const showFilters = {
  partos: {
    espCodigo: true,
    racCodigo: true,
    sexo: false,
    lotCodigo: true,
    catCodigo: true,
    statusCria: false,
    tipoBusca: false,
    considerarDescartados: false,
    ageInMonthCombo: false,
    tipoPrenhez: false
  },
  nascimentos: {
    espCodigo: true,
    racCodigo: true,
    sexo: true,
    lotCodigo: true,
    catCodigo: true,
    statusCria: true,
    tipoBusca: false, 
    considerarDescartados: false,
    ageInMonthCombo: false,
    tipoPrenhez: false
  },
  prenhez: {
    espCodigo: true,
    racCodigo: true,
    sexo: false,
    lotCodigo: true,
    catCodigo: true,
    statusCria: true,
    tipoBusca: false,
    considerarDescartados: true,
    ageInMonthCombo: false,
    tipoPrenhez: false
  },
  prenhas: {
    espCodigo: true,
    racCodigo: true,
    sexo: false,
    lotCodigo: true,
    catCodigo: true,
    statusCria: true,
    considerarDescartados: false,
    ageInMonthCombo: false,
    tipoPrenhez: true
  },
  previsao_desmama: {
    espCodigo: true,
    racCodigo: true,
    sexo: true,
    lotCodigo: true,
    catCodigo: true,
    statusCria: true,
    tipoBusca: true,
    considerarDescartados: false,
    ageInMonthCombo: false,
    tipoPrenhez: false
  },
  desmames_ocorridos: {
    espCodigo: true,
    racCodigo: true,
    sexo: true,
    lotCodigo: true,
    catCodigo: true,
    statusCria: true,
    tipoBusca: true,
    considerarDescartados: false,
    ageInMonthCombo: true,
    tipoPrenhez: false
  },
  desmamados_por_semen: {
    espCodigo: true,
    racCodigo: true,
    sexo: true,
    lotCodigo: true,
    catCodigo: true,
    statusCria: true,
    tipoBusca: false,
    considerarDescartados: false,
    ageInMonthCombo: true,
    tipoPrenhez: false
  }
}

const filterItems = (translations: any) => ({
  tipoBusca: {
    previsao_desmame: [{value: translations["lbl.react.data.nascimento"], valueKey: "0"}, {value: translations["lbl.react.data.previsao"], valueKey: "1"}],
    desmames_ocorridos: [{value: translations["lbl.react.data.nascimento"], valueKey: "0"}, {value: translations["lbl.react.data.de.desmame"], valueKey: "1"}]
  }
})

export { showFilters, filterItems }
